<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2">
        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2">
            <statistics-card-line
              :statistic="sales_today"
              hide-chart
              class="mb-base"
              icon="ArchiveIcon"
              icon-right
              statistic-title="Today's Sales"
            />
          </div>

          <div class="vx-col w-full lg:w-1/2">
            <statistics-card-line
              :statistic="getCustomerCount()"
              hide-chart
              class="mb-base"
              icon="UsersIcon"
              icon-right
              statistic-title="Customers"
              color="success"
            />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2">
            <statistics-card-line
              :statistic="getProductCount()"
              hide-chart
              class="mb-base"
              icon="ShoppingBagIcon"
              icon-right
              statistic-title="Items"
              color="danger"
            />
          </div>

          <div class="vx-col w-full lg:w-1/2">
            <statistics-card-line
              :statistic="getPendingPurchasesCount()"
              hide-chart
              class="mb-base"
              icon="AlertOctagonIcon"
              icon-right
              statistic-title="Pending Purchases"
              color="warning"
            />
          </div>
        </div>
      </div>
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <vx-card
          slot="no-body"
          class="text-center bg-primary-gradient greet-user"
        >
          <img
            src="@/assets/images/elements/decore-left.png"
            class="decore-left"
            alt="Decore Left"
            width="200"
          />
          <img
            src="@/assets/images/elements/decore-right.png"
            class="decore-right"
            alt="Decore Right"
            width="175"
          />
          <feather-icon
            icon="AwardIcon"
            class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow"
            svg-classes="h-8 w-8"
          />
          <h1 class="mb-6 text-white">Hello {{ user.name }},</h1>
          <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">
            Welcome back to your dashboard, you are logged in as
            <strong>
              {{
                user.role == 'admin' ? ` an ${user.role}` : ` a ${user.role}`
              }}
            </strong>
          </p>
        </vx-card>
      </div>
    </div>

    <div v-if="user.role === 'admin'" class="vx-row">
      <div :class="{ 'lg:w-3/5': true }" class="vx-col w-full mb-base">
        <vx-card title="Purchases Pending Delivery">
          <div slot="no-body" class="mt-4">
            <vs-table :data="pendingPurchases" class="table-dark-inverted">
              <template slot="thead">
                <vs-th>BRANCH</vs-th>
                <vs-th>ITEM COUNT</vs-th>
                <vs-th>AMOUNT</vs-th>
                <vs-th>DATE</vs-th>
              </template>

              <template>
                <vs-tr v-for="(tr, index) in pendingPurchases" :key="index">
                  <vs-td :data="pendingPurchases[index].branch">
                    <span class="flex items-center px-2 py-1 rounded">
                      {{ getBranch(tr.branch) }}
                    </span>
                  </vs-td>
                  <vs-td :data="pendingPurchases[index].products">
                    <span class="flex items-center px-2 py-1 rounded">
                      {{ tr.products.length }}
                    </span>
                  </vs-td>
                  <vs-td :data="pendingPurchases[index].amount">
                    <span class="flex items-center px-2 py-1 rounded">
                      {{
                        Number(tr.amount).toLocaleString('en-KE', {
                          style: 'currency',
                          currency: $root.businessData.data.currency,
                        })
                      }}
                    </span>
                  </vs-td>
                  <vs-td :data="pendingPurchases[index].amount">
                    <span class="flex items-center px-2 py-1 rounded">
                      {{ getDate(tr.date_updated) }}
                      <feather-icon
                        icon="ArrowRightIcon"
                        svg-classes="w-5 h-5 hover:text-danger stroke-current "
                        class="ml-2"
                      />
                    </span>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vx-card>
      </div>
      <div :class="{ 'lg:w-2/5': true }" class="vx-col w-full mb-base">
        <vx-card
          v-if="v1Products.length > 0"
          :title="`Upgrade ${v1Products.length} Item${
            v1Products.length === 1 ? '' : 's'
          }`"
          collapse-action
          class="mb-base"
        >
          <p class="mb-3">
            Upgrade items stock data to <code>v2</code> stock system. You have
            {{ v1Products.length }} item{{
              v1Products.length === 1 ? '' : 's'
            }}
            in <code>v1</code>.
          </p>
          <vs-button
            color="success"
            type="border"
            @click.stop="() => migrateItemsToStockV2()"
            :disabled="migratingItems"
            ><div class="vx-row">
              <feather-icon
                icon="ArrowUpCircleIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current cursor-pointer"
              />
              <div class="mx-2">
                Upgrade {{ v1Products.length }} item{{
                  v1Products.length === 1 ? '' : 's'
                }}
              </div>
            </div>
          </vs-button>
        </vx-card>
        <vx-card :title="!checkLowStock > 0 ? 'Low Stock Items' : ''">
          <div v-if="!checkLowStock" class="mt-4">
            <vs-table :data="lowStockProducts">
              <template slot="thead">
                <vs-th>Item</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr v-for="(tr, index) in data" :data="tr" :key="index">
                  <vs-td :data="tr">
                    {{ getProduct(index).product_name }}
                  </vs-td>
                  <template slot="expand" class="expand-user">
                    <div class="items-center w-full">
                      <div v-show="tr.length > 1" class="pb-4">
                        <span
                          >The following are the branches which have low stock
                          items
                        </span>
                      </div>
                      <vs-table :data="tr">
                        <template slot="thead">
                          <vs-th>Branch</vs-th>
                          <vs-th>Stock Left</vs-th>
                        </template>
                        <template slot-scope="{ data }">
                          <vs-tr v-for="(b, index) in data" :key="index">
                            <vs-td :data="data[index].branch">
                              {{ getBranch(b.branch) }}
                            </vs-td>
                            <vs-td :data="data[index].stock">
                              {{ b.stock }}
                            </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                      <div class="pt-2">
                        Stock alarm set at
                        {{
                          Number(getProduct(index).low_stock).toLocaleString()
                        }}
                      </div>
                    </div>
                  </template>
                </vs-tr>
              </template>
            </vs-table>
          </div>
          <div v-else class="vx-row">
            <feather-icon
              icon="CheckIcon"
              class="ml-4 p-2 mb-4 bg-success inline-flex rounded-full text-white shadow"
              svg-classes="h-8 w-8"
            />
            <h5 class="mb-4 ml-2 mt-4">Stocks Look Good</h5>
            <p class="ml-4">
              All branches to have sufficient stock. We'll alert you when a
              item's stock is below the threshold you set.
            </p>
          </div>
        </vx-card>
      </div>
    </div>

    <div class="vx-row pt-4">
      <div class="vx-col w-full">
        <vx-card title="Recent Sales">
          <div slot="no-body" class="mt-4">
            <vs-table
              :data="user.role === 'admin' ? recentSales : mySales"
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>NO.</vs-th>
                <vs-th>CASHIER</vs-th>
                <vs-th>BRANCH</vs-th>
                <vs-th>PAYMENT METHOD</vs-th>
                <vs-th>DISCOUNT</vs-th>
                <vs-th>AMOUNT</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="(tr, index) in data" :key="index">
                  <vs-td>
                    <span># {{ index + 1 }}</span>
                  </vs-td>
                  <vs-td>
                    <span>{{ getUser(tr.cashier) }}</span>
                  </vs-td>
                  <vs-td>
                    <span>{{ tr.branch.branch_name }}</span>
                  </vs-td>
                  <vs-td :data="data[index].payment">
                    <span
                      :style="[
                        tr.credit ? { color: 'red' } : { color: 'green' },
                      ]"
                    >
                      {{ tr.credit ? 'Credit' : tr.payment.name }}
                      {{ tr.credit ? '- UNPAID' : '- PAID' }}
                    </span>
                  </vs-td>
                  <vs-td :data="data[index].discount">
                    <span>{{
                      Number(tr.discount).toLocaleString('en-KE', {
                        style: 'currency',
                        currency: $root.businessData.data.currency,
                      })
                    }}</span>
                  </vs-td>
                  <vs-td :data="data[index].amount">
                    <span>{{
                      Number(tr.amount).toLocaleString('en-KE', {
                        style: 'currency',
                        currency: $root.businessData.data.currency,
                      })
                    }}</span>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import VxTimeline from '@/components/timeline/VxTimeline';
import idb from '@/db/idb';
import VueApexCharts from 'vue-apexcharts';
import analyticsData from './ui-elements/card/analyticsData.js';
const { endpoint } = require('@/../config.js');

export default {
  components: {
    VueApexCharts,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    VxTimeline,
  },
  data() {
    return {
      sales_today: 0,
      total_products: 0,
      total_customers: 0,
      recent_sales: [],
      pending_jobs: [],

      migratingItems: false,

      checkpointReward: {},
      subscribersGained: {},
      ordersRecevied: {},
      salesBarSession: {},
      supportTracker: {},
      productsOrder: {},
      salesRadar: {},

      analyticsData: analyticsData,
      dispatchedOrders: [],

      dateRange: {
        startDate: this.getStartDate(),
        endDate: this.getDate(Date.now()),
      },
    };
  },
  pouch: {
    saleData() {
      return {
        database: 'express',
        selector: {
          type: 'sale',
          date_created: {
            $gte: new Date().setHours(0, 0, 0, 0),
          },
        },
        sort: [
          {
            date_created: 'desc',
          },
        ],
      };
    },
    mySaleData() {
      return {
        database: 'express',
        selector: {
          type: 'sale',
          cashier: this.user._id,
          date_created: {
            $gte: new Date().setHours(0, 0, 0, 0),
          },
        },
        sort: [
          {
            date_created: 'desc',
          },
        ],
      };
    },
    productData() {
      return {
        database: 'express',
        selector: {
          type: 'product',
        },
      };
    },
    customerData() {
      return {
        database: 'express',
        selector: {
          type: 'customer',
        },
      };
    },
    purchaseData() {
      return {
        database: 'express',
        selector: {
          type: 'purchase',
        },
        sort: [
          {
            date_created: 'desc',
          },
        ],
      };
    },
    branchData() {
      return {
        database: 'express',
        selector: {
          type: 'branch',
        },
      };
    },
    userData() {
      return {
        database: 'express',
        selector: {
          type: 'user',
        },
      };
    },
    stockData() {
      return {
        database: 'express',
        selector: {
          type: 'stock',
          // branch_id: this.currentBranch || undefined,
        },
      };
    },
  },
  computed: {
    checkLowStock() {
      return JSON.stringify(this.lowStockProducts) === JSON.stringify({});
    },
    user() {
      return this.$session.get('user');
    },
    pendingJobs() {
      var pj = [];
      for (var i = 0; i < this.pending_jobs.length; i++) {
        if (this.pending_jobs[i]['status'] == false) {
          pj.push(this.pending_jobs[i]);
        }
      }
      return pj.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date_due) - new Date(a.date_due);
      });
    },
    mySales() {
      this.sales_today = this.mySaleData ? this.mySaleData.length : 0;
      return this.mySaleData ? this.mySaleData : [];
    },
    recentSales() {
      this.sales_today = this.saleData ? this.saleData.length : 0;
      return this.saleData ? this.saleData.slice(0, 15) : [];
    },
    products() {
      var productData = this.productData ? this.productData : [];
      var stockData = this.stockData ? this.stockData : [];

      for (let i = 0; i < productData.length; i++) {
        switch (productData[i].version) {
          case 2:
            // V2 item
            productData[i].stockv2 =
              stockData.filter((e) => e.item_id === productData[i]._id) || [];
            break;

          default:
            break;
        }
      }
      return productData;
    },
    v1Products() {
      return this.products.filter((e) => e.version != 2);
    },
    customers() {
      return this.customerData ? this.customerData : [];
    },
    pendingPurchases() {
      return this.purchaseData
        ? this.purchaseData.filter((e) => !e.fulfilled)
        : [];
    },
    lowStockProducts() {
      var p = this.products;
      var ls = {};
      for (let i = 0; i < p.length; i++) {
        const e = p[i];
        if (e.low_stock === 0 || e.low_stock == '0') {
          continue;
        }
        if (e.low_stock) {
          ls[e._id] = [];
          var els = ls[e._id];

          switch (e.version) {
            case 2:
              if (e.stockv2) {
                for (let i = 0; i < e.stockv2.length; i++) {
                  const stock = e.stockv2[i];
                  if (stock.stock <= parseInt(e.low_stock)) {
                    ls[e._id].push({
                      branch: stock.branch_id,
                      stock: stock.stock,
                    });
                  }
                }
              }
              break;

            default:
              if (typeof e.stock === 'object') {
                for (const k in e.stock) {
                  if (e.stock.hasOwnProperty(k)) {
                    const stock = parseInt(e.stock[k]);
                    if (stock <= parseInt(e.low_stock)) {
                      ls[e._id].push({
                        branch: k,
                        stock: stock,
                      });
                    }
                  }
                }
              }
              break;
          }

          if (ls[e._id].length === 0) {
            delete ls[e._id];
          }
        }
      }

      return ls;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  async created() {
    // await this.fill(['currency'], 'business');
    // this.$store.dispatch("employees/fetchEmployees");
    // this.$store.dispatch("vehicles/fetchVehicles");
    // this.$store.dispatch("finance/fetchPaymentMethods");
    // this.$store.dispatch("customers/fetchCustomers");
    // this.$http
    //   .get(
    //     endpoint +
    //       "/api/dashboard/" +
    //       this.getDate2(this.dateRange.startDate) +
    //       "/" +
    //       this.getDate2(this.dateRange.endDate) +
    //       "/"
    //   )
    //   .then((response) => {
    //     this.sales_today = response.data.sales_today;
    //     this.total_products = response.data.total_products;
    //     this.total_customers = response.data.total_customers;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // this.$http
    //   .get(endpoint + "/jobs/")
    //   .then((response) => {
    //     this.pending_jobs = response.data;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // this.$http
    //   .get(endpoint + "/sales/")
    //   .then((response) => {
    //     this.recent_sales = response.data;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  },
  methods: {
    migrateItemsToStockV2() {
      this.migratingItems = true;

      // Create bulk stock items records
      // Bulk update the items to version 2

      var stockItems = [];
      var items = [];

      var timestamp = Date.now();

      for (let i = 0; i < this.v1Products.length; i++) {
        const { stock, ...item } = this.v1Products[i];

        if (!Object(item).hasOwnProperty('version') && item.version != 2) {
          if (typeof stock === 'object') {
            for (const branch in stock) {
              if (stock.hasOwnProperty(branch)) {
                stockItems.push({
                  item_id: item._id,
                  branch_id: branch,
                  stock: Number(stock[branch]) || 0,
                  type: 'stock',
                  date_created: timestamp,
                  valid: true,
                });
              }
            }
          }
        }

        items.push({
          ...item,
          product_name: item.product_name.toUpperCase(),
          wholesale_price: item.wholesale_price || item.retail_price,
          version: 2,
        });
      }

      if (stockItems.length > 0)
        this.$store
          .dispatch('products/migrateV1Stock', stockItems)
          .then((response) => {
            console.debug('migrateV1Stock stockItems', response);
            this.showMessage(
              'icon-check-circle',
              'success',
              'Stock Update Successfully',
              ``,
            );
          })
          .catch((err) =>
            this.showMessage(
              'icon-check-circle',
              'danger',
              'Something went wrong',
              `${err.message || err}`,
            ),
          );

      if (items.length > 0)
        this.$store
          .dispatch('products/migrateV1Stock', items)
          .then((response) => {
            console.debug('migrateV1Stock items', response);
            this.showMessage(
              'icon-check-circle',
              'success',
              'Items Updated Successfully',
              ``,
            );
          })
          .catch((err) =>
            this.showMessage(
              'icon-check-circle',
              'danger',
              'Something went wrong',
              `${err.message || err}`,
            ),
          );

      setTimeout(() => {
        this.migratingItems = false;
      }, 5000);
    },
    async getRecord(type) {
      return await idb.getRecord(type);
    },
    async fill(a, t) {
      let d = (await this.getRecord(t)) || null;
      if (d) {
        for (let i = 0; i < a.length; i++) {
          const e = a[i];
          if (this.hasOwnProperty(e) && d.hasOwnProperty(e)) {
            this[`${e}`] = d[e];
          }
        }
        return d;
      }
      return false;
    },
    getProductCount() {
      return this.products.length;
    },
    getCustomerCount() {
      return this.customers.length;
    },
    getPendingPurchasesCount() {
      return this.pendingPurchases.length;
    },
    getUser(id) {
      var b = 'N/A';
      if (this.userData !== null) {
        var c = this.userData.find((e) => e._id == id);
        if (typeof c == 'object') {
          b = c.name;
        }
      }
      return b;
    },
    getBranch(id) {
      var b = 'N/A';
      if (this.branchData !== null) {
        var c = this.branchData.find((e) => e._id == id);
        if (typeof c == 'object') {
          b = c.branch_name;
        }
      }
      return b;
    },
    getProduct(id) {
      var b = 'N/A';
      if (this.productData !== null) {
        var c = this.productData.find((e) => e._id == id);
        if (typeof c == 'object') {
          b = c;
        } else {
          b = {};
        }
      }
      return b;
    },
    showMessage(icon, color, title, message) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: icon,
        color: color,
      });
    },
    getDate(x) {
      return this.$moment(x).format('DD MMM YYYY');
    },
    getPaymentMethod(n) {
      if (n != null) {
        return this.$store.state.finance.paymentMethods.find((x) => x.id == n)[
          'payment_method'
        ];
      }
      return;
    },
    getStartDate() {
      var d = new Date();
      d.setUTCHours(0, 0, 0, 0);
      return d;
    },
    getCustomer(n) {
      if (n != null) {
        return this.$store.state.customers.customers.find((x) => x.id == n)[
          'name'
        ];
      }
      return;
    },
    getEmployee(n) {
      if (n != null) {
        return this.$store.state.employees.employees.find((x) => x.id == n)[
          'name'
        ];
      }
      return;
    },
    getVehicle(n) {
      if (n != null) {
        return this.$store.state.vehicles.vehicles.find((x) => x.id == n)[
          'number_plate'
        ];
      }
      return;
    },
    getStatusColor(bool) {
      if (bool) {
        return 'success';
      }
      return 'danger';
    },
    getDate2(x) {
      return this.$moment(x).format('YYYY-MM-DD hh:mm:ss');
    },
    openJobs() {
      this.$router.push(`/store/jobs`).catch(() => {});
    },
    openSales() {
      this.$router.push(`/store/sales`).catch(() => {});
    },
  },
};
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
